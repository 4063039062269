<script setup>

  // nyelvi es routing beallitasok
  // const localePath = useLocalePath();
  const { locale, locales } = useI18n();

  const { icon, price, currency, priceclass, iconclass } = defineProps({
    icon: { type: String, default: 'tag' },
    price: { type: Number, default: null },
    currency: { type: String, default: '' },
    priceclass: { type: String, default: 'text-txt-light' },
    iconclass: { type: String, default: '' },
  });

</script>

<template>
  <div class="whitespace-nowrap">
    <span v-if="icon != ''" class="text-lg" :class="iconclass">
      <font-awesome-icon :icon="['fas', icon]" class="mx-1" />
    </span>
    <span class="price" :class="priceclass">{{ 
      internationalCurrencyNumberFormat(
        locales,
        locale,
        currency,
        price
      )
    }}</span>
  </div>
</template>
