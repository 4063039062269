/**
 * 
 * Util: Globalisan elerheto egyszeru function: nemzetközi pénznem formázó
 * 
 * internationalCurrencyNumberFormat()
 * 
 *  params:
 *    - publicationdate: kiadás dátuma
 *    - inventories: egy tömb, ami az adott product raktárkészletét mutatja
 * 
 *  Fontos!
 *    Ezt a fgv-t sokszor meghívjuk, eleinte sok hibás értékkel, amíg be nem tölt a termék
 *    utána már helyes értékeket adja vissza. 
 *    TODO: esetleg csinálhatnánk egy ellenőrzést erre vonatkozóan (ott, ahol meghívtuk)
 * 
*/

// TODO:
//  ide kell kiszervezni a pénznem formázó fgv-t.
//  a WebshopPriceWinthCalculations.vue-ból

interface Locale {
  code: string;
  iso: string;
  // Egyéb tulajdonságok, ha vannak
}

export default function formatPrice(
  availableLocales: Locale[],
  actualLocaleIsoCode: string,
  actualCurrencyCode: string,
  price: number
) {

  const locale = availableLocales.find((locale) => locale.code === actualLocaleIsoCode);
  const localeIso = locale ? locale.iso : 'en-US'; // Alapértelmezett érték, ha nincs találat

  const options = {
    style: 'currency',
    currency: actualCurrencyCode,
  };

  const numberFormatter = new Intl.NumberFormat(localeIso, options);
  const formattedPrice = numberFormatter.format(price);

  // HUF esetén el kell távolítani a tizedeseket
  if (actualCurrencyCode === 'HUF') {
    return formattedPrice.replace(/\D00(?=\D*$)/, ''); // Eltávolítja a tizedeseket
  }

  return formattedPrice;
}
